<template>

  <!-- Login -->
  <div class="app_login_bg" :style="{visibility:login.show?'inherit':'hidden'}">
    <wm-popup v-model:show="login.show" width="100%" height="100%" position="top" :time="600">
      <div class="app_login">
        <div class="logo" :style="{backgroundImage:'url('+require('@/assets/logo.svg')+')', backgroundSize: '60%'}" @click="login.show=false"></div>
        <div class="text">
          <h1>WebMIS全栈基础框架</h1>
          <h2>Vue3 / TypeScript / PHP / Python / SpringBoot / Gin</h2>
        </div>
        <div class="copy">{{ cfg.copy }}</div>
      </div>
    </wm-popup>
  </div>
  <!-- Login End -->

  <!-- Top -->
  <div class="app_top_body">
    <div class="body app_top flex">
      <div class="app_logo"></div>
      <div class="app_nav_body">
        <ul class="app_nav">
          <li
            v-for="(v,k) in menus.nav"
            :key="k"
            :class="v.value==pos[0]?'active':''"
            @click="menusClick([v.value, v.children[0].value, v.children[0].children[1].value])"
          >{{ v.label }}</li>
        </ul>
      </div>
      <ul class="app_top_tool flex">
        <li>
          <div class="ico center" @click="openUrl('https://github.com/webmiss')"><i class="icon ico_github"></i></div>
        </li>
        <li class="qrcode">
          <div class="ico center"><i class="icon ico_qrcode"></i></div>
          <div class="app_qrcode_box">
            <div class="app_qrcode_body">
              <div class="arrow arrow_up"></div>
              <img :src="cfg.apiUrl+'index/qrcode/docs'" />
              <p>手机扫描二维码</p>
            </div>
          </div>
        </li>
        <li class="menus">
          <div class="ico center" @click="isShow=!isShow"><i class="icon ico_menus"></i></div>
        </li>
      </ul>
    </div>
  </div>
  <!-- Top End -->
  <!-- Content -->
  <div class="app_body">
    <div class="app_content body flex">
      <!-- BG -->
      <div class="app_menus_bg" :style="{display:isShow?'block':'none'}" @click="isShow=!isShow"></div>
      <!-- Left -->
      <div class="app_left" :style="{display:isShow?'block':'none'}">
        <!-- Search -->
        <div class="app_sea_body">
          <i class="ui ui_search"></i>
          <input type="text" placeholder="请输入关键字" v-model="sea.key" @input="seaInput()" />
        </div>
        <ul class="app_sea_list" v-if="sea.list.length>0">
          <li
            v-for="(v,k) in sea.list"
            :key="k"
            :class="v.url==pos[1]&&v.value==pos[2]?'active':''"
            @click="menusClick([pos[0], v.url, v.value])"
          >
            {{ v.label }}
          </li>
        </ul>
        <!-- Menus -->
        <div class="app_menus_body scrollbar" v-else>
          <template v-for="(v1,k1) in menus.list" :key="k1">
            <div class="app_menus_title flex" @click="v1.checked=!v1.checked">
              <h2>{{ v1.label }}</h2>
              <span :style="{transform: v1.checked?'rotate(-0deg)':'rotate(-180deg)'}">
                <i class="icon ico_arrow_down center"></i>
              </span>
            </div>
            <div v-show="v1.checked">
              <div
                v-for="(v2,k2) in v1.children"
                :key="k2"
                class="app_menus_li"
                :class="v1.value==pos[1]&&v2.value==pos[2]?'active':''"
                @click="menusClick([pos[0], v1.value, v2.value])"
              >
                <h3>{{ v2.label }}</h3>
              </div>
            </div>
          </template>
        </div>
        <!-- Menus End -->
      </div>
      <!-- Left End -->
      <!-- Right -->
      <div class="app_right">
        <div class="app_addr flex">
          <span>{{ addr }}</span>
          <span class="app_print" @click="clickPrint">打印/下载</span>
        </div>
        <div class="app_html scrollbar">
          <div v-if="docHtml!=''" id="Print" class="doc_html" v-html="docHtml"></div>
          <div v-else class="loading center">Loading...</div>
        </div>
      </div>
      <!-- Right End -->
    </div>
    <div class="app_copy">{{cfg.copy}}</div>
  </div>
  <!-- Content End -->
  

</template>

<style lang="less">
/* 表单缩放问题 */
@media only screen and (min-device-width : 320px) and (max-device-width : 1024px) {
  select:focus, textarea:focus, input:focus { font-size: 16px !important; }
}
/* 样式 */
@import url('./assets/style/icon.less');
@import url('./assets/style/ui.less');
@import url('./assets/style/app.less');
</style>

<script lang="ts" src="./App.ts"></script>
