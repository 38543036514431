import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([{"position":"relative","width":"100%","height":"100%"}, {visibility:_ctx.show?'inherit':'hidden'}])
  }, [
    _createElementVNode("div", {
      ref: "PopupBG",
      class: "wm-popup_bg",
      style: _normalizeStyle({backgroundColor:'rgba(0,0,0,'+_ctx.opacity+')'}),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx._clickBG && _ctx._clickBG(...args)))
    }, null, 4),
    _createElementVNode("div", {
      ref: "PopupBody",
      class: "wm-popup_body",
      style: _normalizeStyle({width:_ctx.width, height:_ctx.height, backgroundColor:_ctx.bgColor, borderRadius:_ctx.radius})
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 4))
}