import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "app_login" }
const _hoisted_2 = { class: "copy" }
const _hoisted_3 = { class: "app_top_body" }
const _hoisted_4 = { class: "body app_top flex" }
const _hoisted_5 = { class: "app_nav_body" }
const _hoisted_6 = { class: "app_nav" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "app_top_tool flex" }
const _hoisted_9 = { class: "qrcode" }
const _hoisted_10 = { class: "app_qrcode_box" }
const _hoisted_11 = { class: "app_qrcode_body" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "menus" }
const _hoisted_14 = { class: "app_body" }
const _hoisted_15 = { class: "app_content body flex" }
const _hoisted_16 = { class: "app_sea_body" }
const _hoisted_17 = {
  key: 0,
  class: "app_sea_list"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = {
  key: 1,
  class: "app_menus_body scrollbar"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "app_right" }
const _hoisted_23 = { class: "app_addr flex" }
const _hoisted_24 = { class: "app_html scrollbar" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = {
  key: 1,
  class: "loading center"
}
const _hoisted_27 = { class: "app_copy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wm_popup = _resolveComponent("wm-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "app_login_bg",
      style: _normalizeStyle({visibility:_ctx.login.show?'inherit':'hidden'})
    }, [
      _createVNode(_component_wm_popup, {
        show: _ctx.login.show,
        "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.login.show) = $event)),
        width: "100%",
        height: "100%",
        position: "top",
        time: 600
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "logo",
              style: _normalizeStyle({backgroundImage:'url('+require('@/assets/logo.svg')+')', backgroundSize: '60%'}),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.login.show=false))
            }, null, 4),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text" }, [
              _createElementVNode("h1", null, "WebMIS全栈基础框架"),
              _createElementVNode("h2", null, "Vue3 / TypeScript / PHP / Python / SpringBoot / Gin")
            ], -1)),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.cfg.copy), 1)
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "app_logo" }, null, -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus.nav, (v, k) => {
              return (_openBlock(), _createElementBlock("li", {
                key: k,
                class: _normalizeClass(v.value==_ctx.pos[0]?'active':''),
                onClick: ($event: any) => (_ctx.menusClick([v.value, v.children[0].value, v.children[0].children[1].value]))
              }, _toDisplayString(v.label), 11, _hoisted_7))
            }), 128))
          ])
        ]),
        _createElementVNode("ul", _hoisted_8, [
          _createElementVNode("li", null, [
            _createElementVNode("div", {
              class: "ico center",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openUrl('https://github.com/webmiss')))
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("i", { class: "icon ico_github" }, null, -1)
            ]))
          ]),
          _createElementVNode("li", _hoisted_9, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "ico center" }, [
              _createElementVNode("i", { class: "icon ico_qrcode" })
            ], -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "arrow arrow_up" }, null, -1)),
                _createElementVNode("img", {
                  src: _ctx.cfg.apiUrl+'index/qrcode/docs'
                }, null, 8, _hoisted_12),
                _cache[11] || (_cache[11] = _createElementVNode("p", null, "手机扫描二维码", -1))
              ])
            ])
          ]),
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("div", {
              class: "ico center",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShow=!_ctx.isShow))
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("i", { class: "icon ico_menus" }, null, -1)
            ]))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", {
          class: "app_menus_bg",
          style: _normalizeStyle({display:_ctx.isShow?'block':'none'}),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShow=!_ctx.isShow))
        }, null, 4),
        _createElementVNode("div", {
          class: "app_left",
          style: _normalizeStyle({display:_ctx.isShow?'block':'none'})
        }, [
          _createElementVNode("div", _hoisted_16, [
            _cache[15] || (_cache[15] = _createElementVNode("i", { class: "ui ui_search" }, null, -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "请输入关键字",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sea.key) = $event)),
              onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.seaInput()))
            }, null, 544), [
              [_vModelText, _ctx.sea.key]
            ])
          ]),
          (_ctx.sea.list.length>0)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_17, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sea.list, (v, k) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: k,
                    class: _normalizeClass(v.url==_ctx.pos[1]&&v.value==_ctx.pos[2]?'active':''),
                    onClick: ($event: any) => (_ctx.menusClick([_ctx.pos[0], v.url, v.value]))
                  }, _toDisplayString(v.label), 11, _hoisted_18))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus.list, (v1, k1) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: k1 }, [
                    _createElementVNode("div", {
                      class: "app_menus_title flex",
                      onClick: ($event: any) => (v1.checked=!v1.checked)
                    }, [
                      _createElementVNode("h2", null, _toDisplayString(v1.label), 1),
                      _createElementVNode("span", {
                        style: _normalizeStyle({transform: v1.checked?'rotate(-0deg)':'rotate(-180deg)'})
                      }, _cache[16] || (_cache[16] = [
                        _createElementVNode("i", { class: "icon ico_arrow_down center" }, null, -1)
                      ]), 4)
                    ], 8, _hoisted_20),
                    _withDirectives(_createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v1.children, (v2, k2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: k2,
                          class: _normalizeClass(["app_menus_li", v1.value==_ctx.pos[1]&&v2.value==_ctx.pos[2]?'active':'']),
                          onClick: ($event: any) => (_ctx.menusClick([_ctx.pos[0], v1.value, v2.value]))
                        }, [
                          _createElementVNode("h3", null, _toDisplayString(v2.label), 1)
                        ], 10, _hoisted_21))
                      }), 128))
                    ], 512), [
                      [_vShow, v1.checked]
                    ])
                  ], 64))
                }), 128))
              ]))
        ], 4),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("span", null, _toDisplayString(_ctx.addr), 1),
            _createElementVNode("span", {
              class: "app_print",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.clickPrint && _ctx.clickPrint(...args)))
            }, "打印/下载")
          ]),
          _createElementVNode("div", _hoisted_24, [
            (_ctx.docHtml!='')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  id: "Print",
                  class: "doc_html",
                  innerHTML: _ctx.docHtml
                }, null, 8, _hoisted_25))
              : (_openBlock(), _createElementBlock("div", _hoisted_26, "Loading..."))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.cfg.copy), 1)
    ])
  ], 64))
}